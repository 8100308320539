'use strict';
(function ($) {

	T.Utils = T.Utils || {};

	T.Utils.Ajax = {

		_timeout: 20000,
		_requests: {},
		_requestCounts: {},

		/**
		 * Performs an Ajax call and returns waitable Promise object.
		 * @param {JQuery.AjaxSettings} options - request options
		 * @param {Function} callback - optional function called when request succeeds
		 * @param {Function} errorCallback  - optional function called when request fails
		 * @param {Number} timeout - optional timeout for request in ms
		 * @returns {JQuery.jqXHR}
		 */
		fragment: function fragment(options, callback, errorCallback, timeout) {
			options = $.extend({}, {
				url: '',
				type: 'GET',
				data: {},
				timeout: timeout || T.Utils.Ajax._timeout,
				contentType: "application/json; charset=utf-8"
			}, options);
			if (!options.method) {
				options.method = options.type;
			}
			delete options.type;

			const done = function (data, textStatus, jqxhr) {
				const unprotect = options.unprotect || options.url.indexOf(T.Utils.Application.getApi()) > -1;

				try {
					if (typeof callback === 'function') {
						callback(unprotect ? T.Utils.Helper.unprotectJson(data, options.skipUnprotect) : data, textStatus, jqxhr);
					}
				} catch (e) {
					T.Utils.Logger.log(e.message, e);
				}
			};
			const fail = function (jqxhr, textStatus, errorThrown) {
				if (typeof errorCallback === 'function') {
					try {
						errorCallback(jqxhr, textStatus, errorThrown);
					}
					catch (e) {
						T.Utils.Logger.log(e.message, e);
						T.Utils.Ajax._handleClientAndServerError(jqxhr);
					}
				} else {
					T.Utils.Logger.log(`${jqxhr.responseText} + " - Generic Error Handler for - " + ${options.url}`, jqxhr);
					T.Utils.Ajax._handleClientAndServerError(jqxhr);
				}
			};
			const $$req = $.ajax(options);

			if (false === options.async) {
				$$req.done(done).fail(fail);
			} else {
				$$req.then(done, fail);
			}

			return $$req;
		},

		/**
		 * Merges Ajax requests for the same client identifier into a single roundtrip, whereafter all clients are notified by their callbacks.
		 * Arbitrary number of calls with the same client identifier are merged as long as the first Ajax call hasn't returned yet, otherwise normal request is sent.
		 * @param {String} clientIdent - client identifier, should be unique for particular service URL and significant parameters
		 * @param {JQuery.AjaxSettings} options - request options
		 * @param {Function} callback - optional function called when request succeeds
		 * @param {Function} errorCallback  - optional function called when request fails
		 * @param {Number} timeout - optional timeout for request in ms
		 * @returns {JQuery.jqXHR}
		 */
		multifragment: function multifragment(clientIdent, options, callback, errorCallback, timeout) {
			let $$req = T.Utils.Ajax._requests[clientIdent];
			if ($$req) {
				T.Utils.Ajax._requestCounts[clientIdent]++;
				$$req.done(function (data, textStatus, jqxhr) {
					T.Utils.Ajax._cleanupRequests(clientIdent);
					if (typeof callback === 'function') {
						try {
							callback(data, textStatus, jqxhr);
						}
						catch (e) {
							T.Utils.Logger.log(e.message, e);
							T.Utils.Ajax._handleClientAndServerError(jqxhr);
						}
					}
					else {
						T.Utils.Logger.log(jqxhr.responseText, jqxhr);
						T.Utils.Ajax._handleClientAndServerError(jqxhr);
					}
				});
				$$req.fail(function (jqxhr, textStatus, errorThrown) {
					T.Utils.Ajax._cleanupRequests(clientIdent);
					if (typeof errorCallback === 'function') {
						errorCallback(jqxhr, textStatus, errorThrown);
					}
				});
			} else {
				$$req = T.Utils.Ajax.fragment(options, callback, errorCallback, timeout);
				T.Utils.Ajax._requests[clientIdent] = $$req;
				T.Utils.Ajax._requestCounts[clientIdent] = 1;
				$$req.always(function () {
					T.Utils.Ajax._cleanupRequests(clientIdent);
				});
			}
			return $$req;
		},

		/**
		 * @private
		 */
		_cleanupRequests: function _cleanupRequests(clientIdent) {
			T.Utils.Ajax._requestCounts[clientIdent] -= 1;
			if (T.Utils.Ajax._requestCounts[clientIdent] && (0 >= T.Utils.Ajax._requestCounts[clientIdent])) {
				delete T.Utils.Ajax._requests[clientIdent];
				delete T.Utils.Ajax._requestCounts[clientIdent];
			}
		},

		/**
		 * @private
		 */
		_handleClientAndServerError: function _handleClientAndServerError(jqxhr) {
			if (jqxhr.status >= 400) {
				T.Utils.View.showGeneralErrorLayer();
			}
		}
	};
})(jQuery);
