'use strict';
// eslint-disable-next-line no-unused-vars
(function ($) {

	T.Utils = T.Utils || {};

	/**
	 * PageInfo functions.
	 *
	 * @author  <markus.hiller@mtc.berlin>
	 * @namespace T.Utils
	 * @class PageInfo
	 */
	T.Utils.PageInfo = {
		send: false,
		_trackLogin: function _trackLogin(identity) {
			const orgiConfig = $(`login-tracking`).data('config');
			const config = {
				ApiUrl: orgiConfig.ApiUrl,
				PageId: 169781,
				Sparte: 9998,
				ContentId: "Login_adac.de",
				Url: `${window.location.origin}/login/`,
				LoginPage: true
			};
			this.send = true;
			this.callEndpoint(true, false, identity, config);
		},

		checkStatus: function checkStatus() {
			T.Utils.Helper.isConsentMatch("Data Warehouse", (consentStatus) => {
				if (consentStatus) {
					T.Utils.Auth.getResolvedIdentity(this._trackLogin.bind(this));
				}
			});
		},

		callEndpoint: function callEndpoint(dwhActive, tpActive, identity, config) {
			T.Utils.Auth.getBearerToken((token) => {
				const options = {
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), config.ApiUrl),
					type: 'POST',
					headers: {
						'Authorization': `Bearer ${token}`
					},
					xhrFields: { withCredentials: true },
					data: {
						PageId: config.PageId,
						Sparte: config.Sparte,
						Url: config.Url,
					},
					contentType: "application/x-www-form-urlencoded;charset=utf-8"
				};
				if (dwhActive || tpActive) {
					if (dwhActive) {
						options.data.Dwh = dwhActive;
					}
					if (tpActive) {
						options.data.Tp = tpActive;
					}
					options.data.ContentId = config.ContentId;
				}
				// special case for /login page
				if (config.LoginPage) {
					options.data.Token = identity.ID;
				}
				T.Utils.Ajax.fragment(options,
					function () {
					},
					function () {
						// don't care
					});
			});
		}

	};
})(jQuery);