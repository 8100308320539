'use strict';

// eslint-disable-next-line no-unused-vars
(function ($) { //NOSONAR
	const authdriver = window.authdriver || {};

	class MsalAuthDriver {
		client;
		accountId = '';
		authority;
		scopes;
		multifactor = false;
		multifactorRedirect;
		eventHandlers = [];

		errorMappings = [
			{ name: 'InteractionRequiredAuthError', errorMessage: /^AADB2C90077\b/, action: 'authorize_logout' },
			{ name: 'AccessDenied', errorMessage: /^AAD_Custom_Error_SessionRevoked\b/, action: 'authorize_logout' },
			{ name: 'access_denied', errorMessage: /^AADB2C90091\b/, action: 'mfa_canceled_by_user' },
			{ name: 'ServerError', errorMessage: /^AADB2C99002\b/, action: 'authorize_logout' },
			{ name: 'ServerError', errorMessage: /^AAD_Custom_Error_SessionRevoked\b/, action: 'authorize_logout' }
		];

		eventAcquireTokenStart = 'msal:acquireTokenStart';
		eventAcquireTokenSuccess = 'msal:acquireTokenSuccess';
		eventAcquireTokenFailure = 'msal:acquireTokenFailure';
		eventLoginSuccess = 'msal:loginSuccess';
		eventRedirectEnd = 'msal:handleRedirectEnd';
		eventInitializeEnd = 'msal:initializeEnd';

		msalEventTracker = {
			'msal:acquireTokenStart': false,
			'msal:acquireTokenSuccess': false,
			'msal:acquireTokenFailure': false,
			'msal:loginSuccess': false,
			'msal:handleRedirectEnd': false,
			'msal:initializeEnd': false,
		};

		tokenRequestQueue = [];
		ssoSilentRequestQueue = [];
		isSsoInProg = false;

		constructor(options, cacheConfig) {
			this.options = options;

			this.isTokenRequestInProgress = false;
			this.codeVerifier = this.generateRandomString();
			this.scopes = options.scope;
			this.multifactor = options.multifactor;
			this.multifactorRedirect = options.multifactorRedirect;
			this.msalInitializationPromise = this.init(this.options, cacheConfig);
		}

		async init(options, cacheConfig) {
			const conf = {
				auth: this.makeAuthConfig(options),
				cache: cacheConfig || {
					cacheLocation: "sessionStorage",
					storeAuthStateInCookie: false
				}
			};
			this.client = new msal.PublicClientApplication(conf);
			this.client.enableAccountStorageEvents();
			this.client.addEventCallback(this.eventHandler.bind(this));
			await this.client.initialize();
		}


		eventHandler(message) {
			const eventType = message.eventType;
			if (this.eventHandlers[eventType]) {
				this.eventHandlers[eventType].forEach(callback => callback(message));
			}

			if (Object.prototype.hasOwnProperty.call(this.msalEventTracker, eventType)) {
				this.msalEventTracker[eventType] = true;
			}
		}

		addEventHandler(name, callback) {
			if (!this.eventHandlers[name]) {
				this.eventHandlers[name] = [];
			}
			this.eventHandlers[name].push(callback);
		}

		removeEventHandler(name, callback) {
			if (!this.eventHandlers[name]) {
				return;
			}
			const index = this.eventHandlers[name].indexOf(callback);
			if (index !== -1) {
				this.eventHandlers[name].splice(index, 1);
			}
		}

		wasMsalEventTriggered(eventName) {
			return this.msalEventTracker[eventName] || false;
		}

		hasAccount() {
			return !!this.accountId;
		}

		matchAccountScopes(scopes, prefix = "") {
			const account = this._setAndGetActiveAccount();
			if (account) {
				const msalObjStr = T.Utils.Store.findEntryByPrefix(`${account.homeAccountId}-${prefix}-accesstoken`);

				if (msalObjStr) {
					const msalObj = JSON.parse(msalObjStr);
					const decodedToken = T.Utils.JWT.decode(msalObj.secret);

					if (decodedToken.isWellformed()) {
						const match = decodedToken.matchScopes(scopes);
						if (match) {
							this.forceToken = false;
							return true;
						}

						const currentScopes = decodedToken.scope.split(" ");
						const requestedScopes = scopes.split(" ");
						requestedScopes.forEach(scope => {
							if (currentScopes.indexOf(scope) === -1) {
								this.forceToken = true;
							}
						});
					}
				}
			}
			return false;
		}

		isLoggedIn() {
			return this.client.getAllAccounts().length > 0;
		}

		login(options) {
			const request = options ? JSON.parse(JSON.stringify(options)) : {};
			request.state = this.createEncodedCombinedState(request.state);
			this.client.loginRedirect(request);
		}

		logout(options) {
			// prevent pollution of parameter object
			const request = options ? JSON.parse(JSON.stringify(options)) : {};
			const currentAccounts = this.client.getAllAccounts();
			const localAccount = this.accountId ? this.client.getAccountByHomeId(this.accountId) : null;
			request.account = currentAccounts && currentAccounts.length ? currentAccounts[0] : localAccount;
			if (request.account) {
				request.state = this.createEncodedCombinedState(request.state);
				this.accountId = "";
				this.client.logoutRedirect(request);
			}
		}

		checkLoginStatus(tokenOptions, successCallback, errorCallback) {
			this.client.handleRedirectPromise().then((response) => {
				this.isMSALInitialized = true;
				this.processTokenRequestQueue();

				tokenOptions.state = this.createEncodedCombinedState(tokenOptions.state);

				if (response && !this.responseWasHandled) {
					this.handleResponse(response, successCallback);
					this.responseWasHandled = true;
				} else if (this._needRedirect(tokenOptions.scopes)) {
					this.aquireTokenRedirect(tokenOptions);
				} else {
					if (this.isSsoInProg) {
						this.addToSsoSilentRequestQueue(successCallback, errorCallback);
					} else {
						this.isSsoInProg = true;
						this.client.ssoSilent(tokenOptions).then((res) => {
							this.isSsoInProg = false;
							this.handleResponse(res, successCallback);
							while (this.ssoSilentRequestQueue.length > 0) {
								const { sCallback } = this.ssoSilentRequestQueue.shift();
								sCallback(res);
							}
						}).catch((err) => {
							this.isSsoInProg = false;
							while (this.ssoSilentRequestQueue.length > 0) {
								const { eCallback } = this.ssoSilentRequestQueue.shift();
								eCallback(err);
							}
							errorCallback(err);
						});
					}
				}

			}).catch('function' === typeof errorCallback ? errorCallback : (err) => {
				if (err.errorCode === 'interaction_in_progress') {
					return;
				}
			});
		}

		_needRedirect(scpArray) {
			const account = this._setAndGetActiveAccount();
			if (account) {
				const url = new URL(this.options.apiauth);
				const msalObjStr = T.Utils.Store.findEntryByPrefix(`${account.homeAccountId}-${url.hostname}-accesstoken`);
				if (msalObjStr) {
					const msalObj = JSON.parse(msalObjStr);
					const decodedToken = T.Utils.JWT.decode(msalObj.secret);

					if (decodedToken.isWellformed()) {
						const scopes = scpArray.join(" ").replaceAll(this.options.scopePfx, "");
						const match = decodedToken.matchScopes(scopes);
						if (match) {
							return false;
						}
						let needRedirect = false;
						const currentScopes = decodedToken.scope.split(" ");
						const requestedScopes = scopes.split(" ");
						requestedScopes.forEach(scope => {
							if (currentScopes.indexOf(scope) === -1 && !needRedirect) {
								needRedirect = this.options.scopesRequireUserInput.toLowerCase().indexOf(scope.toLowerCase()) > -1;
							}
						});
						return needRedirect;
					}
				}
			}
			return false;
		}

		clearTempStorage() {
			const account = this.client.getActiveAccount();
			if (account && (!this.client.activeSilentTokenRequests || this.client.activeSilentTokenRequests.size === 0)) {
				for (const key in sessionStorage) {
					if (key.startsWith(`msal.${account.idTokenClaims.aud}.nonce.id_token.`) ||
						key.startsWith(`msal.${account.idTokenClaims.aud}.authority.`) ||
						key.startsWith(`msal.${account.idTokenClaims.aud}.request.state.`)) {
						T.Utils.Store.set(key, null, T.Utils.Store.SESSION);
					}
				}
			}
		}

		addToTokenRequestQueue(request, successCallback, errorCallback) {
			this.tokenRequestQueue.push({ request, successCallback, errorCallback });
		}

		processTokenRequestQueue() {
			while (this.tokenRequestQueue.length > 0) {
				const { request, successCallback, errorCallback } = this.tokenRequestQueue.shift();
				this.getTokenSilent(request, successCallback, errorCallback);
			}
		}

		addToSsoSilentRequestQueue(sCallback, eCallback) {
			this.ssoSilentRequestQueue.push({ sCallback, eCallback });
		}

		shouldAuthorizeAndLogout(err) {
			return this._processAuthErrorResponse(err, false);
		}

		_processAuthErrorResponse(err) {
			return 'authorize_logout' === this.checkIfInErrorMapping(err);
		}

		checkIfInErrorMapping(params) {
			for (let i = 0; i < this.errorMappings.length; i++) {
				const mapping = this.errorMappings[i];
				if (params.name === mapping.name && mapping.errorMessage.test(params.errorMessage)) {
					return mapping.action;
				}
			}
			return '';
		}

		getErrorFromUrl() {
			const hash = window.location.hash.substr(1);
			const params = new URLSearchParams(hash);
			if (params.has('error')) {
				return {
					name: params.get('error'),
					errorMessage: params.get('error_description') || 'No description available'
				};
			}
			return null;
		}


		getErrorMapping(errorCode) {
			return this.errorMappings.find(mapping => mapping.errorMessage.test(errorCode));
		}

		isMfaCanceledError() {
			return this.getErrorMapping('AADB2C90091');
		}

		getToken(tokenOptions, successCallback, errorCallback) {
			// prevent pollution of parameter object
			const request = tokenOptions ? JSON.parse(JSON.stringify(tokenOptions)) : {};
			request.account = this._setAndGetActiveAccount();
			this.getTokenSilent(request, successCallback, errorCallback);
		}

		_setAndGetActiveAccount() {
			let account;
			const currentAccounts = this.client.getAllAccounts();
			if (currentAccounts.length) {
				account = currentAccounts[0] || (this.accountId ? this.client.getAccountByHomeId(this.accountId) : null);
				this.client.setActiveAccount(account);
			}
			return account;
		}

		getTokenSilent(request, successCallback, errorCallback) {
			if (!this.isMSALInitialized) {
				this.addToTokenRequestQueue(request, successCallback, errorCallback);
				return;
			}
			request.state = this.createEncodedCombinedState(request.state);

			if (this._needRedirect(request.scopes)) {
				this.aquireTokenRedirect(request);
			} else {
				if (this.forceToken) {
					request.forceRefresh = true;
				}
				this.client.acquireTokenSilent(request)
					.then((response) => {
						this.handleResponse(response, successCallback);
					})
					.catch('function' === typeof errorCallback ? errorCallback : (err) => {
						if (err.errorCode === 'no_tokens_found' || err.errorCode === 'monitor_window_timeout') {
							this.debounceTokenRedirect(request);
						}
					});
			}
		}

		debounceTokenRedirect(request) {
			if (this.debounceTimer) {
				clearTimeout(this.debounceTimer);
			}

			this.debounceTimer = setTimeout(() => {
				if (!this.isTokenRequestInProgress) {
					this.aquireTokenRedirect(request);
					this.isTokenRequestInProgress = true;
					this.debounceTimer = null;
					setTimeout(() => {
						this.isTokenRequestInProgress = false;
					}, 200);
				}
			}, 300);
		}
		aquireTokenRedirect(request) {
			this.client.acquireTokenRedirect(request).catch(() => { });
		}

		handleResponse(response, callback) {
			this.accountId = response && response.account ? response.account.homeAccountId : '';
			if (this.accountId) {
				this.client.setActiveAccount(response.account);
			}

			if ('function' === typeof callback) {
				callback(response);
			}
		}

		parseTokenHash(hash) {
			const result = {};
			if (hash && 1 < hash.length) {
				const urlArray = hash.substr(1).split("&");
				urlArray.forEach(function (value) {
					const [key, val] = value.split("=");
					result[key] = decodeURIComponent(val.replace(/\+/g, "%20"));
				});
			}

			if (result.state) {
				const parts = result.state.split('|');

				parts.forEach(part => {
					const decodedPart = atob(part);
					const parsedPart = JSON.parse(decodedPart);

					Object.assign(result, parsedPart);
				});
			}

			return result;
		}

		parseState(state) {
			const result = {};
			if (state) {
				const combinedState = atob(state);
				const parsedCombinedState = JSON.parse(combinedState);

				// eslint-disable-next-line prefer-const
				for (const key in parsedCombinedState) {
					result[key] = parsedCombinedState[key];
				}

			}
			return result;
		}

		sanitizeUrl(url) {
			const safeUrl = document.createElement('a');
			safeUrl.href = url;
			let pathURL = safeUrl.pathname;
			if (!pathURL.startsWith('/')) {
				pathURL = `/${pathURL}`;
			}
			if (!pathURL.endsWith('/')) {
				pathURL += '/';
			}
			if (safeUrl.search.length !== 0) {
				pathURL += safeUrl.search;
			}
			return pathURL;
		}

		makeAuthConfig(options) {
			if (!options || !options.client || !options.apiauth) {
				throw new SyntaxError("Invalid options: client and apiauth are required");
			}
			const result = {
				clientId: options.client,
				authority: options.apiauth,
				knownAuthorities: [],
				redirectUri: options.redirectUri
			};
			const authUrl = new URL(result.authority);

			result.knownAuthorities.push(authUrl.hostname);
			if ('/' !== authUrl.pathname[authUrl.pathname.length - 1]) {
				authUrl.pathname += '/';
			}
			if (options.tenant) {
				authUrl.pathname += `${options.tenant}/`;
			}
			if (options.policy) {
				authUrl.pathname += `${options.policy}/`;
			}
			result.authority = authUrl.toString();
			this.authority = result.authority;

			return result;
		}

		createEncodedCombinedState(state) {
			if (state) {
				return btoa(JSON.stringify(state));
			}

			return '';
		}

		generateRandomString() {
			const array = new Uint8Array(32);
			window.crypto.getRandomValues(array);
			return Array.from(array, dec => (`0${dec.toString(16)}`).substr(-2)).join('');
		}

		base64UrlEncode(str) {
			let base64 = btoa(str).replace(/\+/g, '-').replace(/\//g, '_');

			// Entferne alle '=' am Ende der Zeichenkette ohne regulären Ausdruck
			while (base64.endsWith('=')) {
				base64 = base64.slice(0, -1);
			}

			return base64;
		}

		getCodeChallenge() {
			return this.base64UrlEncode(sha256(this.codeVerifier));
		}

	}

	authdriver.msal = {
		getOrCreate: async function getOrCreate(options, cacheConfig, forceInstance) {
			if (!this._driver || forceInstance) {
				if (!options) {
					throw new Error("Options are required");
				}
				if ('object' !== typeof (msal)) {
					throw new ReferenceError("msal is undefined, load msal-browser library first");
				}
				if (this._driver && this._driver.hasAccount()) {
					this._driver.logout();
				}
				this._driver = new MsalAuthDriver(options, cacheConfig);
			}
			await this._driver.msalInitializationPromise;
			return this._driver;
		},
		getCurrent: function getCurrent() {
			return this._driver;
		}
	};

	window.authdriver = authdriver;
	return authdriver;
})(jQuery);
