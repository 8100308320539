'use strict';
(function ($) {
	let activeXhr = 0;
	$(document).on("ajaxStart", () => {
		activeXhr++;
	});
	$(document).on("ajaxStop", () => {
		activeXhr--;
	});

	T.Utils = T.Utils || {};

	T.Utils.View = {

		_loaderStack: {},
		_loaderCheckTimeout: 20000,
		_maxXhrChecks: 3,
		_layoutDarknessCls: 'a-layout-darkness--loader',

		getIsoDatetime: function getIsoDatetime() {
			return $('meta[name="isodatetime"]').attr('content');
		},

		scrollToElement: function scrollToElement(elem) {
			/* istanbul ignore */
			const offset = elem.offset();
			if (offset) {
				$('html, body').animate({
					scrollTop: offset.top - 100 - S.Utils.ScrollIntoView.$header.outerHeight()
				}, 800);
			}
		},

		isInViewport: function isInViewport(elm) {
			const $elm = $(elm);
			const te = ($elm.offset() || { top: -1000 }).top;
			const be = te + $elm.outerHeight();
			const ts = $(window).scrollTop();
			const bs = ts + $(window).innerHeight();
			return (bs > te) && (ts < be);
		},

		setDataSafe: function setDataSafe(elm, dataName, dataVal) {
			return elm.data(dataName, dataVal).attr(`data-${dataName}`, 'object' === typeof dataVal ? JSON.stringify(dataVal) : dataVal);
		},

		startLoader: function startLoader(loaderId, loaderCheckTimeout, loaderHinttext, loaderHintTimeout) {
			this._clearTimeouts();
			const $layer = this._getLoaderLayer();
			$layer.empty();
			this.loaderQueue = this.loaderQueue || 0;
			this.loaderCheckTries = 0;

			if (loaderId !== undefined && this._loaderStack !== undefined) {
				this._loaderStack[loaderId] = Error().stack;
			}

			if (this.loaderQueue < 1) {
				$layer.removeClass(this._layoutDarknessCls);
				$layer.addClass(this._layoutDarknessCls).fadeIn('fast');
			}
			this.loaderQueue++;

			if (loaderHinttext && loaderHintTimeout) {
				this.loaderHint = setTimeout(this._showLoaderHinttext.bind(this, loaderHinttext), loaderHintTimeout);

			}
			this.loaderCheck = setTimeout(this._checkLoaderQueue.bind(this), loaderCheckTimeout ?? this._loaderCheckTimeout);
		},

		stopLoader: function stopLoader(loaderId) {
			this.loaderQueue = this.loaderQueue || 0;

			if (loaderId !== undefined && this._loaderStack !== undefined) {
				delete this._loaderStack[loaderId];
			}

			this.loaderQueue--;
			if (this.loaderQueue <= 0) {
				this.loaderQueue = 0;
				this._clearTimeouts();
				const $layer = this._getLoaderLayer();
				$layer.fadeOut('fast');
			}
		},

		stopAllLoader: function stopAllLoader() {
			this._clearTimeouts();
			const $layer = this._getLoaderLayer();
			$layer.fadeOut('fast');
			this.loaderQueue = this.loaderQueue || 0;

			this.loaderQueue--;
			if (this.loaderQueue > 0) {
				T.Utils.Logger.info(`${this.loaderQueue} loaders were active while the stop was forced`);
			}
			this.loaderQueue = 0;
		},

		showGeneralErrorLayer: function showGeneralErrorLayer(closeCallback) {
			const $dialogGeneralPageError = $('#dialog_general_page_error');
			if ($dialogGeneralPageError.length > 0) {
				// close all lightbox instances beforehand, otherwise callbacks won't work
				$.magnificPopup.close();
				$.magnificPopup.open({
					mainClass: 'l-lightbox',
					items: {
						src: $dialogGeneralPageError,
						type: 'inline'
					},
					callbacks: {
						beforeOpen: function () {
							// setup classes
							$dialogGeneralPageError.addClass("ll-lightbox-inner");
							if (JL.requestId) {
								$('.js-requestid').toggleClass('h-hidden', false);
								$('.js-requestid-text').text(JL.requestId);
							}
							$('.js-timestamp').text(moment().format('DD.MM.YYYY HH:mm:ss'));
							this.st.mainClass = "mfp-zoom-in l-lightbox";
						},
						close: function () {
							if ('function' === typeof closeCallback) {
								closeCallback();
							}
						}
					}
				});
			}
			this.stopAllLoader();
		},

		_getLoaderLayer: function _getLoaderLayer() {
			return $('.a-layout-darkness');
		},

		_checkLoaderQueue: function _checkLoaderQueue() {
			if (this.loaderQueue > 0) {
				this.loaderCheckTries++;
				if (0 < activeXhr && (!this.loaderCheckTries || this.loaderCheckTries >= this._maxXhrChecks)) {
					// still XHR to load, check one more time
					this.loaderCheck = setTimeout(this._checkLoaderQueue.bind(this), this._loaderCheckTimeout);
					console.warn(`Loader timed out during ${activeXhr} XHR still active`);
				} else {
					let stackMsg = "";
					for (const stackKey in this._loaderStack) {
						stackMsg += `Stack for ${stackKey}: ${this._loaderStack[stackKey]} | `;
					}
					this._loaderStack = {};
					T.Utils.Logger.log(`Loader is still visible, ${activeXhr} XHR active`, stackMsg);
					T.Utils.View.showGeneralErrorLayer();
				}
			}
		},

		_clearTimeouts: function _clearTimeouts() {
			clearTimeout(this.loaderCheck);
			clearTimeout(this.loaderHint);
		},

		_showLoaderHinttext: function _showLoaderHinttext(content) {
			const $layer = this._getLoaderLayer();
			$layer.append($(`<span class="aa-text">${content}</span>`));
		},

		displayDialog: function displayDialog(element, message, isModal = false) {
			const $dialog = $(element);
			if (message) {
				$dialog.find('.error-msg').html(message);
			}
			$.magnificPopup.open({
				items: {
					src: $dialog
				},
				fixedContentPos: true,
				modal: isModal,
				callbacks: {
					beforeOpen: function () {
						// setup classes
						$dialog.addClass("ll-lightbox-inner");
						this.st.mainClass = "mfp-zoom-in l-lightbox";
					}
				}
			});
			$dialog.find('.js-close-lightbox').on('click', this.hideDialog.bind());
		},

		hideDialog: function hideDialog() {
			$.magnificPopup.close();
		}
	};

})(jQuery);
