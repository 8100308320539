/* eslint-disable sonarjs/cognitive-complexity */
'use strict';
/**
 * @fileoverview T.Utils.Auth
 * @author Marc Radziwill, Markus Hiller, Dmitri Zoubkov, Barbara Ascher, Krystian Sadowski
 */

(function ($) { //NOSONAR

	T.Utils = T.Utils || {};

	const OAUTH_SESSIONKEY_ERROR = "oauth.error";
	const SESSIONKEY_TOKEN = 'Token';
	const SESSIONKEY_IDENTITY = 'Application.Identity';
	const SESSIONKEY_LAST_AUTH_BACKEND = "LastAuthBackend";
	const COOKIE_IDENTITY = 'Identity';
	const COOKIE_IDENTITY_REQUIRED = 'IdentityRequired';
	const DEFAULT_PROVIDER = 'msal';

	const FLOW_TYPE = {
		code: 'code',
		token: 'token',
		id_token: 'id_token',
		pkce: 'pkce'
	};

	T.Utils.Auth = {

		initAuthEvents: false,

		/**
		 * Initializes authentication configuration either from supplied options or from &lt;meta> tags of the current page.
		 * This method doesn't need to be called manually, if the current page contains appropriate configuration tags, since it is called automatically on page load.
		 * @param {Object} options - optional, configuration object (f.i. JSON returned from API /api/configurationprovider/loginconfiguration)
		 * @param {string} options.backend - base URL for auth API
		 * @param {string} options.client_id - client ID to use in OAuth calls
		 * @param {string} options.redirect_uri - local URL for OAuth callbacks
		 * @param {string} options.scope - OAuth authentication scope
		 * @param {string} options.authorize_url - OAuth authorize URL (relative to backend)
		 * @param {string} options.register_url - URL for registration of new users (relative to backend)
		 * @param {string} options.logout_url - URL for logging out users (relative to backend)
		 * @param {string} options.token_url - local API URL for access token retrieval (when responce_type=code)
		 * @param {string} options.refreshtokentoken_url - local API URL for refreshing expired access token (when responce_type=code)
		 * @param {string} options.identity_url - API URL for accquiring details of authenticated user (relative to backend)
		 * @param {string} options.response_type - OAuth response type, either "token" or "code"
		 * @param {boolean} force - optional, if true entire configuration will be reinitialized, otherwise subsequent calls have no effect
		 * @memberof T.Utils.Auth
		 */
		// eslint-disable-next-line complexity,sonarjs/cognitive-complexity
		init: async function init(options, force) {
			const inst = T.Utils.Auth || this; // necessary since in async calls "this" changes
			if (inst.apiauth && inst._lastapiauth && inst.driver && !force) {
				return;
			}
			const metaConfig = $('meta[data-key="authconfig"]').data('value') || {};
			const getOpt = (key) => {
				return options ? options[key] : undefined;
			};

			inst.api = getOpt('backend') || metaConfig.backend || $('meta[data-key="backend"]').attr("data-value");
			inst.apiauth = getOpt('backend_auth') || metaConfig.backend_auth || inst.api;
			inst._lastapiauth = T.Utils.Store.get(SESSIONKEY_LAST_AUTH_BACKEND, T.Utils.Store.SESSION);
			T.Utils.Store.set(SESSIONKEY_LAST_AUTH_BACKEND, inst.apiauth, T.Utils.Store.SESSION);
			inst.client = getOpt('client_id') || metaConfig.client_id;
			inst.tenant = getOpt('tenant') || metaConfig.tenant || undefined;
			inst.policy = getOpt('policy') || metaConfig.policy || undefined;
			inst.redirectUri = getOpt('redirect_uri') || metaConfig.redirect_uri;
			inst.scope = getOpt('scope') || metaConfig.scope || 'Rollen Mitgliedsnummer Mitgliedschaft Daten Erweitert Dv Bank Versicherung Profil';
			inst.scopesRequireUserInput = getOpt('scopes_require_user_input') || metaConfig.scopes_require_user_input || '';
			inst.scopePfx = getOpt('scope_pfx') || metaConfig.scope_pfx;
			inst.authorizeURL = getOpt('authorize_url') || metaConfig.authorize_url;
			inst.registerURL = getOpt('register_url') || metaConfig.register_url;
			inst.logoutURL = getOpt('logout_url') || metaConfig.logout_url;
			inst.apitoken = getOpt('token_url') || metaConfig.token_url || '/api/oauth/token';
			inst.apirefreshtoken = getOpt('refreshtokentoken_url') || metaConfig.refreshtokentoken_url || '/api/oauth/refresh/token';
			inst.apiidentity = getOpt('identity_url') || metaConfig.identity_url || '/api/Identity/get';
			inst.flowType = getOpt('response_type') || metaConfig.response_type || FLOW_TYPE.code;

			inst.signupFlowType = getOpt('signup_response_type') || metaConfig.signup_response_type || inst.flowType;
			inst.signupPolicy = getOpt('signup_policy') || metaConfig.signup_policy || inst.policy;
			inst.singupRedirectUri = getOpt('signup_redirect_uri') || metaConfig.signup_redirect_uri || inst.redirectUri;

			inst.mfaPolicy = getOpt('mfa_policy') || metaConfig.mfa_policy || inst.policy;
			inst.mfaeditRedirectUri = getOpt('mfaedit_redirect_uri') || metaConfig.mfaedit_redirect_uri || inst.policy;
			inst.mfaecreateRedirectUri = getOpt('mfaecreate_redirect_uri') || metaConfig.mfaecreate_redirect_uri || inst.policy;
			inst.multifactor = getOpt('multifactor') === 'true' || metaConfig.multifactor || false;
			inst.multifactorRedirect = getOpt('multifactorredirect') || metaConfig.multifactorredirect;
			inst.multifactorCanceledByUserLink = getOpt('multifactorcanceledbyuserlink') || metaConfig.multifactorcanceledbyuserlink;
			inst.mfaChecked = false;

			inst.emailChangePolicy = getOpt('emailchange_policy') || metaConfig.emailchange_policy || inst.policy;
			inst.emailChangeRedirectUri = getOpt('emailchange_redirect_uri') || metaConfig.emailchange_redirect_uri || inst.redirectUri;

			inst.pwdChangePolicy = getOpt('pwdchange_policy') || metaConfig.pwdchange_policy || inst.policy;
			inst.pwdChangeRedirectUri = getOpt('pwdchange_redirect_uri') || metaConfig.pwdchange_redirect_uri || inst.redirectUri;

			inst.tokenAgeThreshold = getOpt('token_max_age') || metaConfig.token_max_age || 10;
			inst.requireReload = !inst.isHelperFrame() && $('[data-requirereload="true"]').length > 0;

			inst.logintrackingviaapil = getOpt('logintrackingviaapil') === 'true' || metaConfig.logintrackingviaapil || false;

			inst.driver = await authdriver.msal.getOrCreate(inst);

			if (!this.initAuthEvents) {
				this.initAuthEvents = true;
				this.handleAuthEvents();
			}

			$(window).on('beforeunload', () => {
				inst.pendingUnload = true;
			});

			if (!window.getToken) {
				window.getToken = this.getToken.bind(this);
			}
		},

		handleAuthEvents: function handleAuthEvents() {
			this.handleEventOrAddListener(this.driver.eventInitializeEnd, () => {
				$(document).trigger('msalInit');
			});
			this.handleEventOrAddListener(this.driver.eventAcquireTokenStart, () => {
				this.checkMfaScopes();
			});
			this.handleEventOrAddListener(this.driver.eventAcquireTokenSuccess, (res) => {
				if (res && res.payload && this.isValidMfaToken(res.payload.accessToken)) {
					if (!this.logintrackingviaapil) {
						this.sendPageInfo();
					}
					this.checkExclusiveContent();
				} else {
					this.authorize();
				}
			});

			this.handleEventOrAddListener(this.driver.eventLoginSuccess, () => {
				if (!this.logintrackingviaapil) {
					this.sendPageInfo();
				}
				this.checkExclusiveContent();
			});

			this.checkExclusiveContent();

		},

		checkExclusiveContent: function checkExclusiveContent() {
			if (T.Utils.ExclusiveContent.isExclusiveCheckPage() && !this.exclusiveCheck) {
				this.exclusiveCheck = true;
				T.Utils.ExclusiveContent.check();
			}
		},

		checkMfaScopes: async function checkMfaScopes() {
			const isLoggedIn = await T.Utils.Auth.isLoggedIn();
			if (!this.mfaChecked && !this.matchAccountScopes(this.scope) && isLoggedIn && !window.location.href.includes('login-check')) {
				this.mfaChecked = true;
				this.getBearerToken(() => { });
			}
		},

		isValidMfaToken: function (token) {
			const jwt = T.Utils.JWT.decode(token);
			const mfaRequired = jwt.scope.toLowerCase().indexOf("mfa-required");
			const mfaEligible = jwt.scope.toLowerCase().indexOf("mfa-eligible");
			return ((mfaRequired > -1 || (mfaEligible > -1 && jwt.mfaSet)) && jwt.mfaExecuted) || (mfaRequired === -1 && mfaEligible === -1) || (mfaEligible > -1 && !jwt.mfaSet);
		},

		matchAccountScopes: function matchAccountScopes(scope) {
			const url = new URL(this.apiauth);
			return this.driver.matchAccountScopes(scope, url.hostname);
		},

		appendToScope: function appendToScope(scope) {
			if (!this.scope.includes(scope)) {
				this.scope = `${this.scope} ${scope}`;
			}
		},

		sendPageInfo: function sendPageInfo() {
			if (!T.Utils.PageInfo.send) {
				T.Utils.PageInfo.send = true;
				T.Utils.PageInfo.checkStatus();
			}
		},

		/**
		 * Überprüft, ob ein oder mehrere Ereignisse ausgelöst wurden.
		 * Ruft die Callback-Funktion sofort auf, wenn das Ereignis bereits ausgelöst wurde,
		 * andernfalls fügt es einen Ereignishandler für das entsprechende Ereignis hinzu.
		 *
		 * @param {string|string[]} events - Ein einzelner Event-Name als String oder ein Array von Event-Namen.
		 * @param {Function} callback - Die Callback-Funktion, die aufgerufen wird, sobald das Ereignis ausgelöst wird.
		 */

		handleEventOrAddListener: async function handleEventOrAddListener(events, callback) {
			if (await T.Utils.Auth.wasEventTriggered(events)) {
				callback();
			} else {
				T.Utils.Auth.addEventHandler(events, callback);
			}
		},

		/**
		 * Adds event listener for msal auth events
		 * @example
		 * T.Utils.Auth.addEventHandler(['msal:handleRedirectEnd', 'msal:loginSuccess', 'msal:acquireTokenSuccess'], this.callback.bind(this));
		 * @param {String|Array} handler - required, event handlers as string or name. Must map the MSAL Events
		 * @memberof T.Utils.Auth
		 */

		addEventHandler: function addEventHandler(handler, callback) {
			if (Array.isArray(handler)) {
				handler.forEach((eventName) => {
					this.driver.addEventHandler(eventName, callback);
				});
			} else {
				this.driver.addEventHandler(handler, callback);
			}
		},

		/**
		 * Removes event listener for msal auth events
		 * @example
		 * T.Utils.Auth.addEventHandler(['msal:handleRedirectEnd', 'msal:loginSuccess', 'msal:acquireTokenSuccess'], this.callback.bind(this));
		 * @param {String|Array} handler - required, event handlers as string or name. Must map the MSAL Events
		 * @memberof T.Utils.Auth
		 */


		removeEventHandler: function removeEventHandler(handler, callback) {
			if (Array.isArray(handler)) {
				handler.forEach((eventName) => {
					this.driver.removeEventHandler(eventName, callback);
				});
			} else {
				this.driver.removeEventHandler(handler, callback);
			}
		},

		/**
		 * Returns true|false if an msal event was triggered
		 * @example
		 * T.Utils.Auth.wasEventTriggered('msal:handleRedirectEnd');
		 * @param {String|Array}  eventName - required, event name as string. Must map the MSAL Events
		 * @memberof T.Utils.Auth
		 */


		wasEventTriggered: async function wasEventTriggered(eventName, every = false) {
			await this.init();

			if (Array.isArray(eventName)) {
				if (every) {
					return eventName.every((event) => this.driver.wasMsalEventTriggered(event));
				} else {
					return eventName.some((event) => this.driver.wasMsalEventTriggered(event));
				}
			} else {
				return this.driver.wasMsalEventTriggered(eventName) || false;
			}

		},

		/**
		 * Generates URL to authentication provider page for registering new users, usually used in sign-up links.
		 * @example
		 * document.querySelector('#register').setAttribute('href', T.Utils.Auth.makeRegisterURL());
		 * @param {Object} options - optional, when supplied its properties override library wide configuration
		 * @param {string} options.client_id - client ID to use in OAuth calls
		 * @param {string} options.redirect_uri - local URL for OAuth callbacks
		 * @param {string} options.scope - OAuth authentication scope
		 * @param {string} options.id_token_hint - optional, JWT token with user data for auth provider to prefill registration form
		 * @returns {string} register URL
		 * @memberof T.Utils.Auth
		 */
		makeRegisterURL: async function makeRegisterURL(options) {
			await this.init();

			return this.makeProviderFormURL(this.singupRedirectUri, "signupPolicy", options);
		},

		makeChangeEmailURL: async function makeChangeEmailURL(options) {
			await this.init();

			return this.makeProviderFormURL(this.emailChangeRedirectUri, "emailChangePolicy", options);
		},

		makeEditMfaURL: async function makeEditMfaURL(options) {
			await this.init();

			return this.makeProviderFormURL(this.mfaeditRedirectUri, "mfaPolicy", options);
		},

		makeCreateMfaURL: async function makeCreateMfaURL(options) {
			await this.init();

			return this.makeProviderFormURL(this.mfaecreateRedirectUri, "mfaPolicy", options);
		},

		makeChangePwdURL: async function makeChangePwdURL(options) {
			await this.init();

			return this.makeProviderFormURL(this.pwdChangeRedirectUri, "pwdChangePolicy", options);
		},

		makeProviderFormURL: async function makeProviderFormURL(redirectUri, policy, options) {
			await this.init();
			const params = options || {};
			const scope = this._buildScope();
			const req = {
				client_id: params.client_id || this.client,
				scope: params.scope || scope.split(),
				state: this.driver.createEncodedCombinedState(this._createState(params.processUrl || this._getCurrentLocation(), policy)),
				redirect_uri: params.redirect_uri || redirectUri,
				response_type: 'code',
				code_challenge: this.driver.getCodeChallenge(),
				code_challenge_method: 'S256'
			};

			if (!this.signupPolicy) {
				req.origin = window.location.href;
			}
			if (params.id_token_hint) {
				req.id_token_hint = params.id_token_hint;
			}
			return T.Utils.Helper.updateUrlParameter(req, this._getAuthURL(this.registerURL, policy));
		},

		/**
		 * Generates URL to user authorization page of authentication provider, usually used in login links.
		 * @example
		 * document.querySelector('#login').setAttribute('href', T.Utils.Auth.makeAuthorizeURL());
		 * @param {Object} options - optional, when supplied its properties override library wide configuration
		 * @param {string} options.client_id - client ID to use in OAuth calls
		 * @param {string} options.redirect_uri - local URL for OAuth callbacks
		 * @param {string} options.scope - OAuth authentication scope
		 * @returns {string} authorize URL
		 * @memberof T.Utils.Auth
		 */
		makeAuthorizeURL: function makeAuthorizeURL() {
			// eslint-disable-next-line no-console
			console.warn('T.Utils.makeAuthorizeURL is deprecated. Please remove the function call from your component. It will be removed.'); //NOSONAR
			return '/';
		},


		/**
		 * Generates URL to authentication provider page which invalidates user session, usually used in action of logout (post) forms.
		 * Call {@link T.Utils.Auth.logout} prior to posting requests to returned URL.
		 * @example
		 * document.querySelector('#logout').setAttribute('action', T.Utils.Auth.makeLogoutURL());
		 * @returns {string} logout URL
		 * @memberof T.Utils.Auth
		 */

		makeLogoutURL: async function makeLogoutURL(options) {
			await this.init();
			const params = options || {};
			const returnUrl = params.redirect_uri || window.location.href;
			return T.Utils.Helper.updateUrlParameter({ returnUrl: returnUrl, post_logout_redirect_uri: returnUrl }, this._getAuthURL(this.logoutURL));
		},

		checkStatus: async function checkStatus() {
			if (T.Utils.Helper.isExperienceEditor() || T.Utils.Helper.isPreviewMode()) {
				return;
			}

			await this.init();

			if (this._checkForMfaError()) {
				const state = this.driver.parseTokenHash(window.location.hash);
				if (state.mfa_mode === 'mfa_redirect') {
					window.location.href = state.processUrlCanceled;
				}
			} else if (this._hasOauthFeedbackToDisplay()) {
				// if we have an error to display, we wait till the User choose an option
				this._waitForUserInput = true;
			} else if (!this._waitForUserInput && !this._hasOauthFeedbackToDisplay()) {

				const req = {
					scopes: this._buildScope().split(' '),
					state: this._createState()
				};

				this.driver.checkLoginStatus(req, (resp) => {

					const oldTokenData = this._getStoredAccessToken(true);
					this._storeAccessToken(resp);
					const token = T.Utils.JWT.decode(resp.accessToken);

					const identity = this.getApplicationIdentity();
					if (identity && !this._identityMatchToken(identity, token)) {
						this._setApplicationIdentity(null);
						this.pendingUnload = true;
						window.location.reload();
					} else if (identity && this._areTokensEqual(oldTokenData, token)) {
						$(document).trigger('identityloaded', identity);
					} else {
						this._acquireIdentiy();
					}

					$(document).trigger('authsuccess', { accessToken: token.source, scopes: resp.scopes });

					if (this.isRedirectURI(window.location.href)) {
						setTimeout(() => {
							const state = this.driver.parseTokenHash(window.location.hash);
							if (state.processUrl) {
								window.location.href = state.processUrl;
							}
						}, 1000);
					}

					this.driver.clearTempStorage();

				}, (err) => {
					$(document).trigger('autherror');

					if (err && (err.errorCode === 'interaction_required' || (err.errorMessage && err.errorMessage.indexOf("This user does not exist") > -1))) {
						this.logout(true);
					}

					this.driver.clearTempStorage();
				});
			}
		},

		_checkForMfaError: function _checkForMfaError() {
			const errorObj = this.driver.getErrorFromUrl();
			return errorObj && this.driver.isMfaCanceledError();
		},

		_hasOauthFeedbackToDisplay() {
			const oauthfeedback = $(document.body).data("oauthfeedback");
			return T.Utils.Store.get(OAUTH_SESSIONKEY_ERROR, T.Utils.Store.SESSION) || oauthfeedback;
		},

		authorize: async function authorize(options) {
			if (this.pendingUnload) {
				return;
			}

			await this.init();
			this.pendingUnload = true;
			const params = options || {};
			const scope = this._buildScope();

			const req = {
				state: this._createState(params.processUrl || this._getCurrentLocation()),
				scopes: params.scope || scope.split()
			};

			if (params.redirect_uri) {
				req.redirectUri = params.redirect_uri;
			}
			const consentStatus = T.Utils.Store.get('Consents', T.Utils.Store.COOKIE);
			if (this.logintrackingviaapil && consentStatus === 'dwh:true') {
				req.extraQueryParameters = {
					...req.extraQueryParameters,
					dwhconsent: true
				};
			}

			this.driver.login(req);
		},

		logout: async function logout(clearRelogin = true) {
			await this.init();
			this.clearStore(clearRelogin);
			$(document).trigger('identitylogout');
			this.driver.logout();
		},

		clearToken: function clearToken() {
			T.Utils.Store.set(SESSIONKEY_TOKEN, null, T.Utils.Store.SESSION);
		},

		logoutFinally: async function logoutFinally(redirectUri, clearRelogin = true) {
			await this.init();
			this.clearStore(clearRelogin);
			$(document).trigger('identitylogout');
			this.driver.logout(redirectUri ? { postLogoutRedirectUri: redirectUri } : null);
		},

		logoutWithError: function logoutWithError(statusCode, errorVariant) {
			const errorUrl = this._getErrorPage(statusCode, errorVariant);
			this.logoutFinally(errorUrl);
		},

		getResolvedIdentity: async function getResolvedIdentity(callback, options) {
			await this.init();
			let result = this.getApplicationIdentity();
			if (T.Utils.Helper.isExperienceEditor()) {
				callback(result);
			}

			if (!this.driver) {
				$(document).one("msalInit", () => {
					this.getResolvedIdentity(callback, options);
				});
				return;
			}


			if (this.pendingIdentity) {
				$(document).one("identityloaded", () => {
					result = this.getApplicationIdentity();
					callback(result);
				});
			} else if (!result && this.driver.hasAccount() && !this.isHelperFrame()) {
				this._acquireIdentiy(callback, options);
			} else if (!result && !this.driver.hasAccount() && !this.isHelperFrame()) {
				$(document).one("authsuccess", () => {
					this.getResolvedIdentity(callback, options);
				});
				$(document).one("autherror", () => {
					callback(result);
				});
				if (this.authsuccess) {
					this.getResolvedIdentity(callback, options);
					this.authsuccess = false;
				} else {
					await T.Utils.Auth.checkStatus();
				}
			} else {
				callback(result);
			}
		},

		_verifyRequest: function _verifyRequest(xsrfToken, successCallback) {
			successCallback();
			// eslint-disable-next-line no-console
			console.warn('T.Utils.Auth_verifyRequest is deprecated. Please remove the function call from your component'); //NOSONAR
		},

		getBearerToken: async function getBearerToken(callback, errorCallback) {
			if (this.pendingUnload) {
				return;
			}

			await this.init();
			this.driver.getToken({
				scopes: this._buildScope().split(' '),
				state: this._createState(this._getCurrentLocation())
			}, (resp) => {
				if (resp && this.isValidMfaToken(resp.accessToken)) {
					callback(resp ? resp.accessToken : undefined);
				} else {
					this.authorize();
				}
			}, errorCallback);
		},

		getToken: async function getToken(callback) {
			try {
				return new Promise((resolve) => {
					this.getBearerToken(
						(token) => {
							if (callback) {
								callback(token, undefined);
							}
							resolve(token);
						},
						(error) => {
							if (callback) {
								callback(undefined, error);
							}
							resolve(undefined);
						}
					);
				});
			} catch (e) {
				if (callback) {
					callback(null, e);
				}
				throw e;
			}
		},

		bgTokenUpdate: async function bgTokenUpdate(forceAuthorize = false, statusResponse) { //NOSONAR
			if (this.pendingTokenUpdate || this.isHelperFrame()) {
				return;
			}
			await this.init();

			const req = {
				scopes: statusResponse && statusResponse.scopes ? statusResponse.scopes : this._buildScope().split(' '),
				state: statusResponse && statusResponse.state ? statusResponse.state : this._createState()
			};

			if (this.flowType === 'pkce' && this.requireReload) {
				req.forceRefresh = true;
			}

			if (this.driver.hasAccount() && !statusResponse && (statusResponse && !statusResponse.accessToken)) {
				this.driver.getTokenSilent(req, (resp) => {
					this._bgUpdateWorker(req, forceAuthorize, resp);
				}, () => {
					this._bgUpdateWorker(req, forceAuthorize);
				});
			} else {
				this._bgUpdateWorker(req, forceAuthorize, statusResponse);
			}
		},

		_bgUpdateWorker: function _bgUpdateWorker(req, forceAuthorize, statusResponse) {
			const token = statusResponse && statusResponse.accessToken ? T.Utils.JWT.decode(statusResponse.accessToken) : null;
			if (!token || (token && moment().diff(moment(token.issuedAt), 'seconds') > this.tokenAgeThreshold)) {
				this.pendingTokenUpdate = true;
				this.driver.getToken(req, (resp) => {
					delete this.pendingTokenUpdate;
					const oldTokenData = this._getStoredAccessToken(true);
					this.authsuccess = true;
					$(document).trigger('authsuccess', resp);
					this._storeAccessToken(resp);
					if (this._needReload(oldTokenData, T.Utils.JWT.decode(resp.accessToken))) {
						this._setApplicationIdentity(null);
						this.pendingUnload = true;
						window.location.reload();
					}
				}, async (err) => {
					delete this.pendingTokenUpdate;
					if (this.driver.shouldAuthorizeAndLogout(err)) {
						if (forceAuthorize) {
							this.authorize();
						} else {
							this.logout(false);
						}
					}
					$(document).trigger('autherror');

					if (await this.isIdentityRequired()) {
						this.clearStore(true);
						this._acquireIdentiy();
					} else if (forceAuthorize) {
						this.authorize();
					}
				});
			} else {
				if (token) {
					this._storeAccessToken(statusResponse);
					$(document).trigger('authsuccess', { accessToken: token.source, scopes: req.scopes });
				}

				const identity = this.getApplicationIdentity();
				if (identity) {
					$(document).trigger('identityloaded', identity);
				} else {
					this._acquireIdentiy(() => { $(document).trigger('identityloaded', identity); }, req);
				}
			}
		},

		_identityMatchToken: function _identityMatchToken(identity, newTokenData) {
			// eslint-disable-next-line eqeqeq
			if (identity == undefined) { //NOSONAR
				return false;
			}
			return identity.Roles.equal(newTokenData.roles);
		},

		_areTokensEqual: function _areTokensEqual(oldTokenData, newTokenData) {
			// eslint-disable-next-line eqeqeq
			if (oldTokenData == undefined) { //NOSONAR
				return false;
			}
			return oldTokenData.roles.equal(newTokenData.roles) && oldTokenData.payload.email === newTokenData.payload.email && oldTokenData.scope === newTokenData.scope;
		},

		_needReload: function _needReload(oldTokenData, newTokenData) {
			// eslint-disable-next-line eqeqeq
			if (oldTokenData == undefined) { //NOSONAR
				return false;
			}

			return !oldTokenData.roles.equal(newTokenData.roles) || oldTokenData.payload.email !== newTokenData.payload.email || this._oldScopesContainsNewScopes(oldTokenData.scope, newTokenData.scope);
		},

		/// Prevent reload if all new scopes already loaded in the old scopes
		_oldScopesContainsNewScopes(oldScopes, newScopes) {
			let needReload = false;
			const oldS = oldScopes.split(" ");
			const newS = newScopes.split(" ");
			newS.forEach(scope => {
				if (oldS.indexOf(scope) === -1) {
					needReload = true;
				}
			});
			return needReload;
		},

		_storeAccessToken: function _storeAccessToken(data) {
			if (!data || !data.accessToken) {
				return;
			}

			this.access_token = T.Utils.JWT.decode(data.accessToken);
			if (this.access_token.isWellformed() && !this.access_token.matchScopes(this.scope)) {
				T.Utils.Logger.info(`Token scope '${this.access_token.scope}' mismatches requested '${this.scope}'`);
			}
			this.access_expires = data.expires_in || this.access_token.expiresIn || 86400; // if no expiration (mock), set to 1 day
			const currentDate = new Date();
			this.access_expiration = moment(currentDate).add((this.access_expires - 5), 's').toDate();

			T.Utils.Store.set(SESSIONKEY_TOKEN, this.access_token.source, T.Utils.Store.SESSION);
			$(document).trigger('tokenloaded', this.access_token.source);
		},

		_getStoredAccessToken: function _getStoredAccessToken(decoded = false) {
			if (!this.access_token) {
				const source = T.Utils.Store.get(SESSIONKEY_TOKEN, T.Utils.Store.SESSION);
				if (source) {
					this.access_token = T.Utils.JWT.decode(source);
				} else {
					return undefined;
				}
			}
			return decoded ? this.access_token : this.access_token.source;
		},

		/**
		 *
		 * @param {IdentityUser} user
		 * @param {string} role
		 * @param {boolean} checkOnlyRole - if true the given must be exclusive (the only role the user has)
		*/
		isValidUser: function isValidUser(user) {
			return !!(user && user.MglNo);
		},

		/**
		 *
		 * @param {IdentityUser} user
		 * @param {string} role
		 * @param {boolean} checkOnlyRole - if true the given must be exclusive (the only role the user has)
		*/
		isInRole: function isInRole(user, role, checkOnlyRole = false) {
			// eslint-disable-next-line eqeqeq
			return !!(user && user.Roles && user.Roles.length && (-1 < user.Roles.indexOf(role.toLowerCase()) || -1 < user.Roles.indexOf(role.capitalize())) && (!checkOnlyRole || user.Roles.length == 1)); //NOSONAR
		},

		/**
		 * Determines current login status of a user.
		 * @returns {boolean} true if current user is logged in or false otherwise.
		 * @memberof T.Utils.Auth
		 */
		isLoggedIn: async function isLoggedIn() {
			await this.init();
			return this.driver.isLoggedIn();
		},

		/**
		 * @returns {boolean} true if identity of current user doesn't match application state.
		 * @memberof T.Utils.Auth
		 */
		isWrongIdentity: async function isWrongIdentity() {
			const isLoggedIn = await this.isLoggedIn();
			return this.getLocalIdentity() && !isLoggedIn;
		},

		/**
		 * @returns {boolean} true if authentication backend has changed since last roundtrip
		 * @memberof T.Utils.Auth
		 */
		isWrongBackend: function isWrongBackend() {
			// eslint-disable-next-line eqeqeq
			return this.apiauth != this._lastapiauth; // NOSONAR DON'T EVER "FIX" IT!!!!
		},

		/**
		 * Checks requirement of identity for current user.
		 * Requirement is assumed if some third party sets IDENTITY_REQUIRED cookie and current user is not logged in.
		 * @returns {boolean} true if request for identity needed or false otherwise.
		 * @memberof T.Utils.Auth
		 */
		isIdentityRequired: async function isIdentityRequired() {
			return ("1" === String(T.Utils.Store.get(COOKIE_IDENTITY_REQUIRED, T.Utils.Store.COOKIE))) || this.isWrongIdentity();
		},

		getLocalIdentity: function getLocalIdentity() {
			return T.Utils.Store.get(COOKIE_IDENTITY, T.Utils.Store.COOKIE);
		},

		getApplicationIdentity: function getApplicationIdentity() {
			if (!this._identity) {
				this._identity = T.Utils.Store.get(SESSIONKEY_IDENTITY, T.Utils.Store.SESSION);
			}
			return this._identity;
		},

		clearStore: function clearStore(clearRelogin = true) {
			delete this.access_token;
			delete this._identity;

			this._setApplicationIdentity(null);
			this._setLocalIdentity(null);
			if (clearRelogin) {
				T.Utils.Store.set(COOKIE_IDENTITY_REQUIRED, null, T.Utils.Store.COOKIE);
			}
			T.Utils.Store.set(SESSIONKEY_TOKEN, null, T.Utils.Store.SESSION);
			T.Utils.Store.set(SESSIONKEY_IDENTITY, null, T.Utils.Store.SESSION);
			T.Utils.Store.set(COOKIE_IDENTITY, null, T.Utils.Store.COOKIE);

		},
		isHelperFrame: function isHelperFrame() {
			// eslint-disable-next-line eqeqeq
			return window.parent && window.parent != self; //NOSONAR
		},
		isRedirectURI: async function isRedirectURI(url) {
			await this.init();
			return [this.redirectUri, this.singupRedirectUri, this.emailChangeRedirectUri, this.pwdChangeRedirectUri].some((uri) => {
				return (url || window.location.href).startsWith(uri);
			});
		},

		_setLocalIdentity: function _setLocalIdentity(identity) {
			const data = identity ? {
				UserId: identity.ID || '',
				Roles: identity.Roles
			} : null;
			if (identity && identity.UserData) {
				data.Anrede = identity.UserData.Anrede || '';
				data.Vorname = identity.UserData.Vorname || '';
				data.Nachname = identity.UserData.Nachname || '';
				data.Firma = identity.UserData.Firma || '';
				data.Geburtsdatum = identity.UserData.Geburtsdatum || '';
			}
			T.Utils.Store.set(COOKIE_IDENTITY, data, T.Utils.Store.COOKIE);
		},
		_setApplicationIdentity: function _setApplicationIdentity(identity) {
			this._identity = identity;
			return T.Utils.Store.set(SESSIONKEY_IDENTITY, identity, T.Utils.Store.SESSION);
		},
		_acquireIdentiy: function _acquireIdentiy(callback) {
			if (this.pendingUnload || this.pendingIdentity) {
				return;
			}
			this.pendingIdentity = true;
			this._requestIdentity((identity) => {
				this._setLocalIdentity(identity);
				this._setApplicationIdentity(identity);
				if (callback) {
					callback(identity);
				}
				delete this.pendingIdentity;
				$(document).trigger('identityloaded', identity);
			}, (jqXHR) => {
				this._setLocalIdentity(null);
				this._setApplicationIdentity(null);
				delete this.pendingIdentity;
				if ("0" === String(jqXHR.status)) {
					jqXHR.status = '400';
				}
				this._errorHandling(jqXHR);
			});
		},
		_requestIdentity: function (successCallback, errorCallback) {
			this.getBearerToken(async (token) => {
				T.Utils.Ajax.multifragment('identity', {
					url: await this._getIdentityURL(),
					headers: {
						'Authorization': `Bearer ${token}`
					}
				}, (identity) => {
					successCallback(identity);
				}, (response) => {
					this.clearStore();
					// eslint-disable-next-line eqeqeq
					if ('function' == typeof errorCallback) { //NOSONAR
						errorCallback(response);
					}
				});
			}, () => {
				this.authorize();
			});
		},

		_errorHandling: function (jqxhr) {
			const errorCode = jqxhr.status.toString().substr(0, 3);
			let msg = "";
			if ('400' === errorCode && jqxhr.responseJSON && jqxhr.responseJSON.Messages) {
				for (let i = 0; !msg && i < jqxhr.responseJSON.Messages.length; i++) {
					if (jqxhr.responseJSON.Messages[i].Message === 'UserExpired') {
						msg = jqxhr.responseJSON.Messages[i].Message;
					}
				}
			}
			this.logoutWithError(errorCode, msg);
		},
		_getErrorPage: function _getErrorPage(statusCode, errorVariant) {
			let suffix = statusCode;
			if (errorVariant) {
				suffix += `-${errorVariant.toLowerCase()}`;
			}
			let result;
			if (-1 < ['500', '400', '404', '403'].indexOf(statusCode)) {
				result = `/global-content/${suffix}/`;
			}
			return result;
		},
		_getIdentityURL: async function _getIdentityURL() {
			await this.init();
			return T.Utils.Helper.appendURIPath(this.api, this.apiidentity);
		},
		_buildScope: function _buildScope() {
			let result = this.scope;
			if (this.scopePfx) {
				result = this.scopePfx + result.split(" ").reduce((accumulator, currentValue) => {
					return `${accumulator} ${this.scopePfx}${currentValue}`;
				});
			}
			return result;
		},
		_createEncodedCombinedState: function _createEncodedCombinedState(url, policy) {
			const state = this._createState(url, policy);
			state.state = $('input[name=__RequestVerificationToken]').val();

			return btoa(JSON.stringify(state));
		},

		_createState: function _createState(url, policy) {
			const result = {
				src: DEFAULT_PROVIDER,
				processUrl: url
			};

			if (this.multifactor && this.multifactorCanceledByUserLink) {
				result.processUrlCanceled = this.multifactorCanceledByUserLink;
				result.mfa_mode = 'mfa_redirect';
			}
			const pol = this[policy || 'policy'];
			if (pol) {
				result.policy = pol;
			}
			return result;
		},
		_getCurrentLocation: function _getCurrentLocation() {
			let url = `${window.location.pathname}${window.location.search}`;
			if ('/' !== url.charAt(0)) {
				url = `/${url}`;
			}
			return url;
		},
		_getAuthURL: function _getAuthURL(relativePath, policy) {
			let result = this.apiauth;
			if (this.tenant) {
				result = T.Utils.Helper.appendURIPath(result, this.tenant);
			}
			const pol = this[policy || "policy"];
			if (pol) {
				result = T.Utils.Helper.appendURIPath(result, pol);
			}
			return T.Utils.Helper.appendURIPath(result, relativePath);
		}
	};

	window.T = T;
	const metaConfig = $('meta[data-key="authconfig"]').data('value') || {};
	const isMock = metaConfig && metaConfig.backend && metaConfig.backend.indexOf("mockup") !== -1 ? true : false;

	if (!$('body').hasClass('js-develop') && !isMock) {
		setTimeout(() => {
			T.Utils.Auth.checkStatus();
		});
	}

	$(window).on('focus', () => {
		T.Utils.Helper.scheduleTask(T.Utils.Auth.checkStatus.bind(T.Utils.Auth), 'background');
	});

})(jQuery);
