'use strict';
/**
 * @fileoverview T.Utils.Headless
 * @author Dmitri Zoubkov
 */
// eslint-disable-next-line no-unused-vars
(function ($) {

	T.Utils = T.Utils || {};

	/**
	 * Utilities used to initialize other Utils class in headless environment.
	 * @namespace Headless
	 * @memberof T.Utils
	 */
	T.Utils.Headless = {
		/**
		 * Loads and configures {@link T.Utils.Auth} class.
		 * @example
		 * T.Utils.Headless.auth().then((auth) => {
		 *     auth.authorize();
		 * });
		 * @param {string} configapi - optional, URL to login configuration API
		 * @returns {Promise} a promise which - when resolved - provides access to fully initialized {@link T.Utils.Auth} class.
		 */
		auth: function auth(configapi) {
			return new Promise((resolve, reject) => {
				if (T.Utils.Auth && T.Utils.Auth.api) {
					resolve(T.Utils.Auth);
				} else {
					T.Utils.Ajax.fragment({ url: configapi || "/api/configurationprovider/loginconfiguration" }, (response) => {
						T.Utils.Auth.init(response);
						return resolve(T.Utils.Auth);
					}, reject);
				}
			});
		}
	};

})(jQuery);