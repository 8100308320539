'use strict';
(function () {

	T.Utils = T.Utils || {};

	/**
	* set up global logger
	*
	* @private
	*/
	function _config() {
		window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
			T.Utils.Logger.log(errorMsg, errorObj, url, lineNumber, column);
			//if set to 'true' browser will not start own error handling
			return true;
		};
	}

	T.Utils.Logger = {
		init: function () {
			if (undefined === window.JL) {
				window.JL = function JL() {
					return {
						fatalException: function () {
							console.error.apply(null, arguments);
						},
						info: function () {
							console.log.apply(null, arguments); // eslint-disable-line no-console
						},
					};
				};
			}
			_config();
		},

		log: function (errorMsg, errorObj, url, lineNumber, column) {
			if (JL) {
				JL().fatalException({
					'msg': 'Exception!',
					'errorMsg': errorMsg,
					'url': url,
					'line number': lineNumber,
					'column': column
				}, errorObj);
			} else {
				console.error(errorMsg, errorObj, url, lineNumber, column);
			}
		},

		info: function (msg) {
			if (JL) {
				JL().info(msg);
			} else {
				console.log(msg); // eslint-disable-line no-console
			}
		}
	};

	T.Utils.Logger.init();

})();
