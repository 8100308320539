'use strict';
(function ($) {

	T.Utils = T.Utils || {};

	/**
	 * Based on:
	 * https://gist.github.com/Fluidbyte/4718380
	 */

	T.Utils.Store = {

		/**
		 * constants
		 */
		SESSION: "session",
		COOKIE: "cookie",
		LOCAL: "local",
		VARIABLE: "variable",

		/**
		 * Cookies and Local Storage Warning Layer visibility
		 */
		cookiesWarningLayerShown: false,

		/**
		 * localStorage Support
		 */
		lsSupport: null,

		storage: null,

		/**
		 * Store an item in localstorage (or a cookie as a fallback)
		 *
		 * @param key {string}    The key to save the value in
		 * @param value {*}        The value to be saved (can be an object as well)
		 */
		config: function (key) {
			switch (key) {
				case 'session':
					this.storage = this._getSessionStorageIfAvailable();
					break;
				case 'cookie':
					this.storage = 'cookie';
					break;
				case 'local':
					this.storage = this._getLocalStorageIfAvailable();
					break;
				case 'variable':
					this.storage = this._getVariableStorage();
					break;
				default:
					this.storage = this._getLocalStorageIfAvailable();
					break;
			}
		},

		_showNoCookieAndStorageWarning: function () {
			const $dialogCookieDenier = $('#dialogCookieDenier');
			if ($dialogCookieDenier.length > 0 && !this.cookiesWarningLayerShown) {
				this.cookiesWarningLayerShown = true;
				$.magnificPopup.open({
					mainClass: 'l-lightbox',
					items: {
						src: $dialogCookieDenier,
						type: 'inline'
					},
					callbacks: {
						beforeOpen: function () {
							$dialogCookieDenier.addClass("ll-lightbox-inner");
							this.st.mainClass = "mfp-zoom-in l-lightbox";
						},
						close: function () {
							this.cookiesWarningLayerShown = false;
						}
					}
				});
			}
		},

		/**
		 * Store an item in localstorage (or a cookie as a fallback)
		 *
		 * @param key {string}    The key to save the value in
		 * @param value {*}       The value to be saved (can be an object as well)#
		 * @param exp             Days how long a cookie valid is
		 */
		set: function (key, value, storage, exp = 30, isdomain = false) {
			if (storage) {
				this.config(storage);
			}

			// Check for native localStorage support
			this._getLsSupport();

			// If value is detected, set new or modify store
			if (typeof value !== 'undefined' && value !== null) {
				// Convert object values to JSON
				if (typeof value === 'object') {
					value = JSON.stringify(value);
				}
				// Set the store
				if (this.storage !== 'cookie') { // Native support
					try {
						this.storage.setItem(key, value);
					} catch (e) {
						this._showNoCookieAndStorageWarning();
					}
				}
				else { // Use Cookie
					this._setCookie(key, value, exp, isdomain);
				}
			}

			// Null or undefined specified, remove store
			if (value === null) {
				if (this.storage !== 'cookie') { // Native support
					try {
						this.storage.removeItem(key);
					} catch (e) {
						this._showNoCookieAndStorageWarning();
					}
				}
				else { // Use cookie
					this._setCookie(key, null, 0, isdomain);
				}
			}
		},

		get: function (key, storage) {
			let data;
			if (storage) {
				this.config(storage);
			}

			// Check for native localStorage support
			this._getLsSupport();

			// Get the value
			if (this.storage !== 'cookie') { // Native support
				try {
					data = this.storage.getItem(key);
				} catch (e) {
					this._showNoCookieAndStorageWarning();
				}
			}
			else { // Use cookie
				data = this._getCookie(key);
			}

			// Try to parse JSON...
			try {
				data = JSON.parse(data);
			}
			catch (e) {
				// no further step here.
			}

			return data;
		},

		keys: function keys(storage) {
			try {
				if (storage) {
					this.config(storage);
				}
				if ('cookie' === this.storage) {
					return document.cookie ? document.cookie.split(';').map(x => x.trim().split('=')[0]) : [];
				}

				const data = this.storage;
				const result = {
					length: data.length
				};
				result.it = result[Symbol.iterator] = function () {
					let index = -1;
					return {
						next() {
							index++;
							if (index >= data.length) {
								return { done: true };
							}
							return {
								done: false,
								value: data.key(index)
							};
						}
					};
				};
				return result;

			} catch (e) {
				this._showNoCookieAndStorageWarning();
			}
			return undefined;
		},

		/**
		 * Creates new cookie or removes cookie with negative expiration
		 * @param  key       The key or identifier for the store
		 * @param  value     Contents of the store
		 * @param  exp       Expiration - creation defaults to 30 days
		 */
		_setCookie: function (key, value, exp, isdomain) {
			// Convert object values to JSON
			if (typeof value === 'object') {
				value = JSON.stringify(value);
			}

			let issecure = "";
			if (window.location.protocol === "https:") {
				issecure = "; secure";
			}
			let domain = "";
			if (isdomain) {
				domain = location.hostname.replace(/^[^.]+\./, ".");
				domain = domain === location.hostname ? "" : `; domain=${domain}`;
			}

			try {
				if (0 === exp) {
					document.cookie = `${key}=;expires=${(new Date(0)).toGMTString()}${domain}; path=/${issecure}`;
				} else {
					if (key === 'Identity' || 0 > exp) {
						document.cookie = `${key}=${encodeURIComponent(value)}${domain}; path=/${issecure}`;
					} else {
						const date = new Date();
						date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
						const expires = `; expires=${date.toGMTString()}`;
						document.cookie = `${key}=${encodeURIComponent(value)}${expires}${domain}; path=/${issecure}`;
					}
				}
			} catch (e) {
				this._showNoCookieAndStorageWarning();
			}
		},

		/**
		 * Returns contents of cookie
		 * @param  key        The key or identifier for the store
		 */
		_getCookie: function (key) {
			const nameEQ = `${key}=`;
			let ca = [];

			try {
				ca = document.cookie.split(';');
			} catch (e) {
				this._showNoCookieAndStorageWarning();
			}

			for (let i = 0, max = ca.length; i < max; i++) {
				let c = ca[i];
				while (c.charAt(0) === ' ') {
					c = c.substring(1, c.length);
				}
				if (c.indexOf(nameEQ) === 0) {
					return decodeURIComponent(c.substring(nameEQ.length, c.length));
				}
			}

			return null;
		},

		/**
		 * Check if localStorage is supported by the browser
		 */
		_getLsSupport: function () {
			// Return if the support has already been defined
			// eslint-disable-next-line eqeqeq
			if (this.lsSupport != undefined) { //NOSONAR we don't need type check here
				return;
			}

			// Set to true if localstorage is supported of false if localstorage is not supported
			this.lsSupport = this._getLocalStorageIfAvailable() || this._getSessionStorageIfAvailable();
		},

		/**
		 * Returns local storage, if available
		 */
		_getLocalStorageIfAvailable: function () {
			try {
				return (window.localStorage && typeof window.localStorage.getItem === 'function') ? window.localStorage : null;
			} catch (e) {
				return null;
			}
		},

		/**
		 * Returns session storage, if available
		 */
		_getSessionStorageIfAvailable: function () {
			try {
				return (window.sessionStorage && typeof window.sessionStorage.getItem === 'function') ? window.sessionStorage : null;
			} catch (e) {
				return null;
			}
		},


		variableStorage: {},

		/**
		 * Returns variable storage
		 */
		_getVariableStorage: function () {
			return {
				getItem: (key) => {
					return this.variableStorage[key];
				},
				setItem: (key, value) => {
					this.variableStorage[key] = value;
				},
				removeItem: (key) => {
					this.variableStorage[key] = null;
				}
			};
		},

		findEntryByPrefix: function (prefix) {
			for (let i = 0; i < sessionStorage.length; i++) {
				const key = sessionStorage.key(i);
				if (key && key.startsWith(prefix)) {
					return sessionStorage.getItem(key);
				}
			}
			return null;
		}
	};
})(jQuery);
