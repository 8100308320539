'use strict';
/**
 * @fileoverview T.Utils.JWT
 * @author Dmitri Zoubkov
 */
// eslint-disable-next-line no-unused-vars
(function ($) {
	T.Utils = T.Utils || {};

	class JwtToken {
		constructor(encodedToken) {
			this.source = encodedToken;
		}
		get header() {
			if (!this._parsed) {
				this._parse();
			}
			return this._header;
		}
		get payload() {
			if (!this._parsed) {
				this._parse();
			}
			return this._payload;
		}
		get signature() {
			if (!this._parsed) {
				this._parse();
			}
			return this._sig;
		}
		get issuedAt() {
			if (this.payload && this.payload.iat) {
				return new Date(this.payload.iat * 1000);
			}
			return new Date(0);
		}
		get expiration() {
			if (this.payload && this.payload.exp) {
				return new Date(this.payload.exp * 1000);
			}
			return null;
		}
		get expiresIn() {
			const exp = this.expiration;
			if (exp) {
				return moment(exp).diff(moment(), 'seconds');
			}
			return 0;
		}
		get subject() {
			if (this.payload) {
				return this.payload.sub;
			}
			return "";
		}
		get scope() {
			if (this.payload) {
				return this.payload.scp;
			}
			return "";
		}
		get roles() {
			let result;
			if (this.payload) {
				result = this.payload.roles;
			}
			return result || [];
		}
		get mfaExecuted() {
			if (this.payload) {
				return this.payload.mfaExecuted;
			}
			return false;
		}
		get mfaSet() {
			if (this.payload) {
				return this.payload.mfaSet;
			}
			return false;
		}
		isWellformed() {
			if (!this._parsed) {
				this._parse();
			}
			return this._header && this._payload && this._sig;
		}
		compareTimeOf(token) {
			const jwt = 'object' === typeof token ? token : new JwtToken(token);
			const t1 = this.issuedAt, t2 = jwt.issuedAt;
			if (t1 < t2) {
				return -1;
			}
			if (t1 > t2) {
				return 1;
			}
			return 0;
		}
		matchScopes(scopes) {
			const scp1 = (this.scope || "").split(' ').map(x => x.toLowerCase()).sort();
			const scp2 = (scopes || "").split(' ').map(x => x.toLowerCase()).sort();
			return scp1.toString() === scp2.toString();
		}
		matchRoles(roles) {
			return this.roles.map(x => x.toLowerCase()).sort().toString() === (roles || []).map(x => x.toLowerCase()).sort().toString();
		}
		matchLocalIdentity(identity) {
			if (identity) {
				if (identity.UserId !== this.subject) {
					return false;
				}
				return this.matchRoles(identity.Roles);
			}
			return false;
		}
		matchToken(token) {
			const jwt = 'object' === typeof token ? token : new JwtToken(token);
			if (jwt) {
				return this.subject === jwt.subject && this.matchScopes(jwt.scope) && this.matchRoles(jwt.roles);
			}
			return false;
		}
		_parse() {
			if (this.source) {
				const parts = this.source.split('.');
				if (parts && 3 <= parts.length) {
					try {
						this._header = this._decodePart(parts[0]);
						this._payload = this._decodePart(parts[1]);
						this._sig = parts[2];
					} catch (e) {
						T.Utils.Logger.log(`Not a valid JWT token ${this.source}`, e, window.location);
					}
				}
			}
			this._parsed = true;
		}
		_decodePart(part) {
			if (part) {
				return JSON.parse(atob(part));
			}
			return null;
		}
		toString() {
			if (this.payload) {
				return `{"header":${JSON.stringify(this.header)},"payload":${JSON.stringify(this.payload)}}`;
			}
			return this.source;
		}
	}

	T.Utils.JWT = {
		decode: function decode(token) {
			return new JwtToken(token);
		}
	};
})(jQuery);
