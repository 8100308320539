'use strict';
(function ($) {

	/*istanbul ignore next*/
	T.Utils = T.Utils || {};

	T.Utils.Application = {
		/**
		 * Initializes the application setup
		 * @void
		 */
		init: function () {
			const dataValue = 'data-value';
			this.api = $('meta[data-key="backend"]').attr(dataValue);
			this.apim = $('meta[data-key="backendapim"]').attr(dataValue);
			this.client = $('meta[data-key="client"]').attr(dataValue);
			this.redirectUri = $('meta[data-key="redirect_uri"]').attr(dataValue);
			this.apimSubscriptionKey = $('meta[data-key="apimsubsriptionkey"]').attr(dataValue);
			this.apiClientAuth = $('meta[data-key="apiclientauth"]').attr(dataValue);
		},
		/**
		 * Set application.init to sessionstore
		 * @param {Object} data
		 */
		setApplicationStore: function (data) {
			T.Utils.Store.set('application.init', data, T.Utils.Store.SESSION);
		},
		/**
		 * Returns token by given key
		 * @param key {String}
		 * @returns {*}
		 */
		getToken: function (key) {
			return T.Utils.Store.get(key, T.Utils.Store.SESSION);
		},
		/**
		 * Clears key specific token
		 * @param {String} key
		 * @void
		 */
		clearToken: function (key) {
			T.Utils.Store.set(key, null, T.Utils.Store.SESSION);
		},
		/**
		 * Getter for api host
		 * @returns {String}
		 */
		getApi: function () {
			return this.api;
		},
		/**
		 * Getter for apim host
		 * @returns {String}
		 */
		getApiM: function () {
			return this.apim;
		},
		getApiMSubscriptionKey: function () {
			return this.apimSubscriptionKey;
		},

		/**
		 * Getter for OAuth client
		 * @returns {String}
		 */
		getClient: function () {
			return this.client;
		},
		/**
		 * Getter for OAuth redirect URI
		 * @returns {String}
		 */
		getRedirectUri: function () {
			return this.redirectUri;
		},
		getApiClientAuth: function () {
			return this.apiClientAuth;
		},
		/**
		 * Setter for api host
		 * @param {String} api
		 * @void
		 */
		setApi: function (api) {
			this.api = api;
		},
		/**
		 * Setter for apim host
		 * @param {String} apim
		 * @void
		 */
		setApiM: function (apim) {
			this.apim = apim;
		},
		setApiMSubscriptionKey: function (value) {
			this.apimSubscriptionKey = value;
		},

		/**
		 * Setter for OAuth client
		 * @param {String} client
		 * @void
		 */
		setClient: function (client) {
			this.client = client;
		},
		/**
		 * Setter for OAuth redirect URI
		 * @param {String} uri
		 * @void
		 */
		setRedirectUri: function (uri) {
			this.redirectUri = uri;
		},
		setApiClientAuth: function (value) {
			this.apiClientAuth = value;
		}
	};

})(jQuery);