'use strict';
/**
 * @fileoverview T.Utils.ExclusiveContent
 * @author Marc Radziwill
 */
// eslint-disable-next-line no-unused-vars
(function ($) {

	T.Utils = T.Utils || {};
	/**
	 * Utilities used to handle exclusive content
	 * @namespace ExclusiveContent
	 * @memberof T.Utils
	*/
	T.Utils.ExclusiveContent = {

		cookieName: 'exclusivecontent',

		isExclusiveCheckPage: function isExclusiveCheckPage() {
			return $('exclusive-content').length;
		},

		check: function check() {
			this._parseConfig();
			T.Utils.Auth.getResolvedIdentity(this._checkRoles.bind(this));
		},

		_parseConfig: function _parseConfig() {
			const exclusiveContentCookie = T.Utils.Store.get(this.cookieName, T.Utils.Store.COOKIE);
			this.config = exclusiveContentCookie ? exclusiveContentCookie : {};
		},


		_isMfaPage: function _isMfaPage() {
			return this.config && this.config.mfaScope && this.config.mfaScope !== 'no';
		},

		_checkRoles: function _checkRoles(user) {
			if (user) {
				if (T.Utils.Auth.isValidUser(user) && this._isValidConfig()) {
					const usergroups = this.config.roles;
					const userCanRead = usergroups.some((role => { return T.Utils.Auth.isInRole(user, role); }));

					if (!userCanRead) {
						T.Utils.Store.set(this.cookieName, null, T.Utils.Store.COOKIE);
						T.Utils.Helper.routeToUrl(this.config.errorUrl);
					} else if (userCanRead && !this.config.alreadyRead) {
						this._processUser();
					}
				} else {
					T.Utils.Helper.routeToUrl(this.config.errorUrl);
				}
			} else {
				if (this._isMfaPage()) {
					T.Utils.Auth.appendToScope(this.config.mfaScope);
					if (!T.Utils.Auth.matchAccountScopes(T.Utils.Auth.scope)) {
						T.Utils.Auth.authorize();
					} else {
						this._processUser();
					}
				} else {
					T.Utils.Auth.authorize();
				}
			}
		},

		_processUser: function _processUser() {
			this.config.canUseContent = true;
			this.config.alreadyRead = true;

			T.Utils.Store.set(this.cookieName, this.config, T.Utils.Store.COOKIE, 1);

			let successUrl = this.config.successUrl;
			if (T.Utils.Helper.isMediaUrl(successUrl)) {
				successUrl = `/${successUrl}`;
				let domainWithHttps = window.location.origin;
				if (!domainWithHttps.startsWith('https')) {
					domainWithHttps = `https://${domainWithHttps.split('//')[1]}`;
				}
				window.location.href = `${domainWithHttps}/${successUrl}`;
			} else {
				window.location.href = this.config.successUrl;
			}
		},

		_isValidConfig: function _isValidConfig() {
			return this.config && this.config.errorUrl && this.config.successUrl && this.config.roles;
		},


	};

})(jQuery);